import React from "react";
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

import HMbg1 from '../Assets/bgImages/HMbg1.jpeg'
import HMbg2 from '../Assets/bgImages/HMbg2.jpeg'
import HMbg3 from '../Assets/bgImages/HMbg3.jpeg'
import HMbg4 from '../Assets/bgImages/HMbg4.png'

import e1 from '../Assets/event12.webp'
import e2 from '../Assets/event1.webp'
import e3 from '../Assets/event2.webp'
import e4 from '../Assets/event4.jpeg'
import e5 from '../Assets/event5.jpeg'
import e6 from '../Assets/event6.jpeg'
import e7 from '../Assets/event7.jpeg'
import e8 from '../Assets/event8.jpeg'
import e9 from '../Assets/event9.jpeg'
import Slider from "react-slick";
import CarouselCard from '../Components/CarouselCard';
import EventCarousel from '../Components/EventCarousel';

import carouselData from '../carouselData';

import p1 from '../Assets/56d6a8_65c1045993404f11a6041e9ab4cd12a7~mv2.webp'
import p2 from '../Assets/56d6a8_be56f826bf6e4887a8f0feeab943bbbd~mv2.webp'

import HMbgGlobal1 from '../Assets/bgImages/HMbgGlobal1.jpeg'
import HMbgGlobal2 from '../Assets/bgImages/HMbgGlobal2.jpeg'
import HMbgGlobal3 from '../Assets/bgImages/HMbgGlobal3.jpeg'
import VideoComponent from "../Components/VideoComponent";

function Home() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    const events = [
        { imageSrc: e1, title: 'SFPE Japan', description: '' },
        { imageSrc: e2, title: 'Inception Of SFPE', description: '' },
        { imageSrc: e3, title: 'Fire Modelling - 2019 Group', description: '' },
        { imageSrc: e4, title: 'ARMAMENT RESEARCH BOARD', description: '' },
        { imageSrc: e5, title: 'ARMREB Certificate', description: '' },
        { imageSrc: e6, title: 'Felicitated by the DRDO', description: 'We are delighted to announce that Prof. K. B. Mishra has been felicitated by the DRDO, New Delhi, for his outstanding contribution in the project titled Simulation and Modeling of Fire-Mist Interaction. TRAG congratulates Prof. Mishra and wishes him more of such achievements in the future.' },
        { imageSrc: e7, title: '10th Meeting of Fire Loss Preventiion Forum of India.', description: '' },
        { imageSrc: e8, title: 'Fire Safety Expo 2024 at Yashobhoomi, New Delhi', description: 'Prof. Mishra delivered an invited talk at Fire Safety Expo 2024 at Yashobhoomi, New Delhi ' },
        { imageSrc: e9, title: 'Fire Safety Expo 2024 at Yashobhoomi, New Delhi', description: 'Prof. Mishra delivered an invited talk at Fire Safety Expo 2024 at Yashobhoomi, New Delhi ' },

    ];
    return (
        <>
            <Navbar />
            
                <div
                    className="relative text-white py-20 flex justify-center items-center w-full bg-black"
                    style={{
                        backgroundImage: `url(${HMbg1})`,   // Background image
                        backgroundSize: 'contain',          // Keep the aspect ratio, shows the full image
                        backgroundPosition: 'center',       // Centers the image
                        backgroundRepeat: 'no-repeat',      // Prevents repeating
                        height: 'auto',                     // Height can adjust based on content
                        width: 'full',                     // Full viewport width
                    }}
                >
                    <div className="p-4 relative flex flex-col justify-center items-center h-full w-full max-w-6xl bg-opacity-70 sm:p-12 md:p-16 lg:p-24 rounded-xl leading-loose">
                        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-extrabold text-center leading-loose textshadow">
                            Technological Risk Research & <br className="hidden sm:block" />
                            Analysis Group <span className="text-blue-400 textshadow">(TRAG)</span>
                        </h1>
                        <p className="mt-4 text-lg sm:text-xl md:text-2xl italic font-light text-center textshadow">
                            ~Striving for a safer tomorrow~
                        </p>
                    </div>
                </div>
                <div
                className="bg-no-repeat bg-cover bg-center w-full h-full"
                style={{ backgroundImage: `url(${HMbgGlobal1})` }}
                >
                <div className="p-4">
                <VideoComponent/>
                </div>
                <section className='text-white font-bold banner-p'>
                    <p className="textshadow">TRAG is a multidisciplinary research group aiming to prevent losses of all kinds that occur due to the technology revolution. We work to make technology safer for society. We use the latest technology available to ensure that all possible risks of fire, explosion, and dispersion are minimized, and even if they occur, then effective mitigation strategies are adopted. We have the expertise and resources to answer many of your questions on risks with processes, plants, and infrastructure. Please share your thoughts and give us an opportunity to interact with you.</p>
                </section>
                <h1 className="text-3xl font-bold text-center mb-8 textshadow text-white">Events</h1>
                <Slider {...settings}>
                    {events.map((event, index) => (
                        <EventCarousel
                            key={index}
                            imageSrc={event.imageSrc}
                            title={event.title}
                            description={event.description}
                            
                        />
                    ))}
                </Slider>
            </div>
            <div
                className="min-h-screen h-auto bg-no-repeat bg-cover bg-center lg:bg-cover md:bg-cover sm:bg-cover"
                style={{ backgroundImage: `url(${HMbgGlobal2})` }}
            >
                <div className=' px-4 sm:px-6 lg:px-8'>
                    <div className='text-center mb-2'>
                        <h1 className='SSD textshadow text-white'>
                            Research, Development, and Consultancy
                        </h1>
                    </div>
                    {/* Background Image Section */}
                    <div
                        className='relative rounded-lg overflow-hidden justify-evenly bg-black'
                        style={{
                            backgroundImage: `url(${HMbg2})`,
                            backgroundSize: 'contain',        // Ensures image covers the whole area
                            backgroundPosition: 'center',   // Keeps the focal point in the center
                            backgroundRepeat: 'no-repeat',  // Prevents image repetition
                        }}
                    >
                        {/* Dark overlay for content readability */}
                        <div className='absolute inset-0'></div>

                        {/* Content Section */}
                        <div className='relative w-full mx-auto bg-zinc-900 bg-opacity-70 rounded-lg overflow-hidden'>
                            <div className='flex flex-wrap justify-evenly gap-10 px-6 py-8 md:py-12'>
                                <div className='w-full md:w-1/2 lg:w-1/3'>
                                    <h2 className='text-xl font-bold text-white mb-4'>Risk Assessment</h2>
                                    <p className='text-white'>
                                        TRAG performs in-depth assessments to identify and analyze risks related to fire, explosion, and hazardous material dispersion in industrial and infrastructural contexts.
                                    </p>
                                </div>
                                <div className='w-full md:w-1/2 lg:w-1/3'>
                                    <h2 className='text-xl font-bold text-white mb-4'>Applied Computational Engineering</h2>
                                    <p className='text-white'>
                                        We utilize state-of-the-art computational models to simulate combustion, fire, explosion, and dispersion in a wide variety of engineering applications, aiding in predicting and managing potential risks effectively.
                                    </p>
                                </div>
                                <div className='w-full md:w-1/2 lg:w-1/3'>
                                    <h2 className='text-xl font-bold text-white mb-4'>
                                        Advanced Measurement and Data Collection
                                    </h2>
                                    <p className='text-white'>
                                        Equipped with advanced measurement and detailed dataset capabilities, we develop and implement robust safety strategies, including risk mitigation plans, emergency response protocols, and safety system designs to manage technological risks.
                                    </p>
                                </div>
                                <div className='w-full md:w-1/2 lg:w-1/3'>
                                    <h2 className='text-xl font-bold text-white mb-4'>
                                        Third-Party Review and Training Program
                                    </h2>
                                    <p className='text-white'>
                                        With a strong commitment to deliver quality solutions for our clients, we rely on comprehensive research on cutting-edge technology to review safety and risk assessment programs/reports for existing and new facilities.
                                    </p>
                                    <p className='text-white'>
                                        We continuously develop new training programs and customize the old ones to meet technological needs, offering workshops with hands-on sessions on software used worldwide to acquaint stakeholders with the latest safety practices and technological advancements.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-6">
                    <Slider {...settings} dots='false'>
                        {carouselData.map((event, index) => (
                            <CarouselCard
                                key={index}
                                imageSrc={event.imageSrc}
                            />
                        ))}
                    </Slider>
                </div>
            </div>
            <div
                className="min-h-full bg-no-repeat bg-top lg:bg-cover md:bg-cover sm:bg-cover"
                style={{ backgroundImage: `url(${HMbgGlobal3})` }}
            >
                <div className="SSD p-8 rounded-lg overflow-hidden">
                    <h1 className="text-3xl md:text-4xl font-bold text-center mb-6 textshadow text-white">
                        Our Esteemed Stakeholders
                    </h1>

                    <div className="partners grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 justify-items-center">
                        <img
                            src={p1}
                            alt="Partner 1"
                            className="w-full sm:w-3/4 lg:w-2/3 min-w-[280px] max-w-[350px] border-4 border-gray-200 rounded-lg shadow-md transition-transform duration-300 ease-in-out hover:scale-105 hover:border-gray-400"
                        />
                        <img
                            src={p2}
                            alt="Partner 2"
                            className="w-full sm:w-3/4 lg:w-2/3 min-w-[280px] max-w-[350px] border-4 border-gray-200 rounded-lg shadow-md transition-transform duration-300 ease-in-out hover:scale-105 hover:border-gray-400"
                        />
                    </div>
                </div>

                <div
                    className='relative p-4 rounded-t-lg overflow-hidden justify-evenly bg-black'
                    style={{
                        backgroundImage: `url(${HMbg4})`,
                        backgroundSize: 'contain',        // Ensures image covers the whole area
                        backgroundPosition: 'center',   // Keeps the focal point in the center
                        backgroundRepeat: 'no-repeat',  // Prevents image repetition
                    }}
                >
                    <div className="absolute inset-0 bg-zinc-900 bg-opacity-80 border-b border-white"></div>
                    <div className="container relative mx-auto flex justify-center items-center">
                        <div className="w-full md:w-2/3 text-center text-white textshadow px-6 md:px-12">
                            <div className="mb-8">
                                <h1 className="text-3xl font-bold textshadow">TRAG's Vision</h1>
                                <p className="mt-4 text-lg font-semibold textshadow">
                                    "TRAG’s aim is to upgrade every technology to its cutting-edge by making computational engineering an integral part of their workflow."
                                </p>
                            </div>
                            <div>
                                <h1 className="text-3xl font-bold textshadow">TRAG's Mission</h1>
                                <p className="mt-4 text-lg font-semibold textshadow">
                                    Every 1% improvement to your existing technology can give you a "sustainable competitive advantage" on a global stage. Our scientists and engineers bring that advantage to your company by providing advanced end-to-end solutions that are accurate, low-cost, and sustainable. At the core of this vision is the pride in our scientific rigor, proven track record, and team spirit.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Home;
